import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { colors,media } from '../../../lib/style-utils'

const ButtonWrap = styled.div``

const PrimaryButtonAni = styled(AniLink)`
    text-align: center;
    background-color: white;
    border-radius: 4rem;
    font-size: 0.8rem;
    color: ${colors.dark_grey};
    box-shadow: 0px 10px 40px rgba(20, 20, 20, 0.2);
    padding: 0.4rem 2rem;
    ${media.tablet`
        bottom: 7rem;
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
        border: 1.2px solid ${colors.dark_grey};
        min-width: 10rem;
        :hover{
            transition: background 0.3s ease;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.25), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
            border-color: orange;
            color: orange;
        }
    `};
`

const PrimaryButtonStatic = styled(Link)`
    text-align: center;
    background-color: white;
    border-radius: 4rem;
    font-size: 0.8rem;
    color: ${colors.dark_grey};
    box-shadow: 0px 10px 40px rgba(20, 20, 20, 0.2);
    padding: 0.4rem 2rem;
    ${media.tablet`
        bottom: 7rem;
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
        border: 1.2px solid ${colors.dark_grey};
        min-width: 10rem;
        :hover{
            transition: background 0.3s ease;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.25), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
            border-color: orange;
            color: orange;
        }
    `};
`

const PrimaryButtonWrap = (props) => {
        // check if button is aniLink or not 
        const isAniLink = props.isAniLink
        return (
            <ButtonWrap>
                { isAniLink &&
                    <PrimaryButtonAni to={props.to} cover direction={props.direction} duration={1.2} bg="orange">{props.text}</PrimaryButtonAni>
                }
                { !isAniLink &&
                    <PrimaryButtonStatic href={props.to}>{props.text}</PrimaryButtonStatic>
                }
            </ButtonWrap>

        )
}

export default PrimaryButtonWrap
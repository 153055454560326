import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"
import styled from 'styled-components'

import { fonts, colors, media } from '../lib/style-utils'
import Layout from "../components/layout"
import Section from '../styles/Section'
import GlobalTextStyles from '../components/General/Text/GlobalTextStyles'
import RevealOpacity from '../components/Animation/RevealOpacity'
import SEO from "../components/seo"
import PrimaryButton from '../components/General/button/PrimaryButton'
import { FormattedMessage } from 'react-intl';

/* SectionExtended */
const SectionExtended = styled(Section)`
    position: relative;
    top: 0;
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
        margin: 0 auto;
    `}
`

const SectionText = styled.div`
    padding: 4rem 0 4rem 0;
    font-family: ${fonts.Montserrat};
    ${media.desktop`
        padding: 6rem 0 8rem 0;
    `};
`


const TextMain = styled.h1`
    display: flex;
    flex-direction: column;
    font-weight:800;
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: white;

    ${media.tablet`
        font-size: 4rem;
        line-height: 3rem;
    `};
    ${media.desktop`
        font-size: 5rem;
        line-height: 4rem;
        margin-bottom: 1rem;
    `};

    ${media.midDesktop`
        font-size: 5rem;
        line-height: 4rem;
    `};
`

const TextWe = styled.h2`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    color: ${colors.dark_grey};

    ${media.tablet`
        font-size: 2rem;
        line-height: 2rem;
    `};
    ${media.desktop`
        font-size: 2rem;
        line-height: 2rem;
    `};

    ${media.midDesktop`
        font-size: 2rem;
        line-height: 2rem;
    `};
`

const MainContainer = styled.div`
    width: 100%;
`

const HeaderContainer = styled.div`
    width: 100%;
    background: ${colors.gradient};
`

const TextIntro = styled.div`
    padding: 1.5rem 0 0 0;
    ${media.desktop`
        max-width: 60%;
        float: right;
    `}
    ${media.lgDesktop`
        max-width: 50%;
        float: right;
    `}
`

const PageContent = styled.div`
    margin-top: -2rem;
    background-color: ${colors.white};
    padding: 3rem 1rem;
    ${media.tablet`
        padding: 4rem 3rem;
        margin-top: -4rem;
    `}
`

const NotFoundPage = props => {
  const seoData = get(props, 'data.pageData.nodes[0].seo', [])
  let data = {
    title: seoData.title,
    description: seoData.description,
    lang: 'nl',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.pageData.nodes[0].slug,
    slugEn: props.data.translatedUrl.nodes[0].slug,
  }

  return (
  <Layout data={props.data.site} location={props.location} slugs={slugs}>
    <SEO {...data}/>
    <MainContainer>
      <HeaderContainer>
          <SectionExtended>
              <SectionText>
                  <RevealOpacity>
                      <TextMain>
                        404
                      </TextMain>
                      <TextWe>
                        <FormattedMessage id="404Message1"/>
                      </TextWe>
                      <TextIntro>
                          <GlobalTextStyles>
                              <p><FormattedMessage id="404Message2"/></p>
                          </GlobalTextStyles>
                      </TextIntro>
                  </RevealOpacity>
              </SectionText>
          </SectionExtended>
      </HeaderContainer>
      <SectionExtended>
          <PageContent>
            <RevealOpacity>
              <p>
                <FormattedMessage id="404Message3"/>
              </p>
              <p>
                <PrimaryButton to="mailto:hello@superkraft.be" text="hello@superkraft.be" isAniLink={false}/>
              </p>
              <p>
                <br/>
                <FormattedMessage id="404Message4"/><br/>
              </p>
              <p>
                <PrimaryButton to="tel:+3293300733" text="+329 330 07 33" isAniLink={false}/>
              </p>
            </RevealOpacity>
          </PageContent>
      </SectionExtended>
    </MainContainer>
  </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  translatedUrl: allCraftPageErrorPageErrorEntry(filter: {siteId: {eq: 1}}) {
    nodes{
      slug
    }
  }
  pageData: allCraftPageErrorPageErrorEntry(filter: {siteId: {eq: 2}}) {
    nodes {
      title
      slug
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced {
          robots
        }
      }
    }
  }
}
`